import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  NotFound: {
    paddingTop: 100,
    alignItems: 'center',
  },
}));

export default function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.NotFound}>
      <h3>Sorry, page not found!</h3>
    </div>
  );
}
