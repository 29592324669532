import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt='Logo'
      style={{ width: 75, height: 75 }}
      src={process.env.PUBLIC_URL + '/logo.png'}
      {...props}
    />
  );
};

export default Logo;
