const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.citytrax.net/dev/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_xaVda0djw',
    APP_CLIENT_ID: '4troq96b6eav3eeukkqnlp7f2c',
    IDENTITY_POOL_ID: 'us-east-1:1b1ffe89-2f60-4832-91ed-4a775d76cce0',
  },
};

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.citytrax.net/prod/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_FGpC8NVjB',
    APP_CLIENT_ID: '1bb19tlbcuqomgluskfrgiufm8',
    IDENTITY_POOL_ID: 'us-east-1:1e42dfa3-053e-4908-93d0-7e7a8fde9243',
  },
};

// Default to dev if not set!
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
