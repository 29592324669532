export async function selectDisaster(dispatch, disasterPayload) {
  try {
    dispatch({ type: 'SET_DISASTER', payload: disasterPayload });
    localStorage.setItem('selectedDisaster', JSON.stringify(disasterPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
export async function selectBasecamp(dispatch, basecampPayload) {
  try {
    dispatch({ type: 'SET_BASECAMP', payload: basecampPayload });
    localStorage.setItem('selectedBasecamp', JSON.stringify(basecampPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
export async function selectProperty(dispatch, propertyPayload) {
  try {
    dispatch({ type: 'SET_PROPERTY', payload: propertyPayload });
    localStorage.setItem('selectedProperty', JSON.stringify(propertyPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
export async function selectRoom(dispatch, roomPayload) {
  try {
    dispatch({ type: 'SET_ROOM', payload: roomPayload });
    localStorage.setItem('selectedRoom', JSON.stringify(roomPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
export async function selectOccupant(dispatch, occupantPayload) {
  try {
    dispatch({ type: 'SET_OCCUPANT', payload: occupantPayload });
    localStorage.setItem('selectedOccupant', JSON.stringify(occupantPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
export async function selectUser(dispatch, userPayload) {
  try {
    dispatch({ type: 'SET_USER', payload: userPayload });
    localStorage.setItem('selectedUser', JSON.stringify(userPayload));
  } catch (error) {
    console.log(error);
    dispatch({ type: 'SET_ERROR', error: error });
  }
}
