let user = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).email
  : '';
let username = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).username
  : '';
let token = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).accessToken
  : '';
let userLevel = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).userLevel
  : '';

export const initialState = {
  userDetails: '' || user,
  username: '' || username,
  token: '' || token,
  userLevel: '' || userLevel,
  challenge: null,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        username: action.payload.username,
        userDetails: action.payload.attributes.email,
        challenge: null,
        token: action.payload.signInUserSession.accessToken.jwtToken,
      };
    case 'LOGIN_CHALLENGED':
      return {
        ...initialState,
        userDetails: action.payload,
        challenge: action.payload.challengeName,
      };
    case 'SET_USER_LEVEL':
      return {
        ...initialState,
        userLevel: action.payload,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        username: '',
        userDetails: '',
        challenge: null,
        userLevel: null,
        token: '',
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        errorMessage: action.error.message,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
