import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './Routes';
import { AuthProvider } from './contexts/auth';
import { AppProvider } from './contexts/app';
import AppRoute from './components/AppRoute';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AppProvider>
          <Router>
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                {routes.map((route) => (
                  <AppRoute
                    key={route.path}
                    path={route.path}
                    layout={route.layout}
                    component={route.component}
                    isPrivate={route.isPrivate}
                    isAdmin={route.isAdmin}
                  />
                ))}
              </Switch>
            </Suspense>
          </Router>
        </AppProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
