let selectedDisaster = localStorage.getItem('selectedDisaster')
  ? JSON.parse(localStorage.getItem('selectedDisaster'))
  : '';

let selectedBasecamp = localStorage.getItem('selectedBasecamp')
  ? JSON.parse(localStorage.getItem('selectedBasecamp'))
  : '';

let selectedProperty = localStorage.getItem('selectedProperty')
  ? JSON.parse(localStorage.getItem('selectedProperty'))
  : '';

let selectedRoom = localStorage.getItem('selectedRoom')
  ? JSON.parse(localStorage.getItem('selectedRoom'))
  : '';

let selectedOccupant = localStorage.getItem('selectedOccupant')
  ? JSON.parse(localStorage.getItem('selectedOccupant'))
  : '';

let selectedUser = localStorage.getItem('selectedUser')
  ? JSON.parse(localStorage.getItem('selectedUser'))
  : '';

export const initialState = {
  selectedDisaster: null || selectedDisaster,
  selectedBasecamp: null || selectedBasecamp,
  selectedProperty: null || selectedProperty,
  selectedRoom: null || selectedRoom,
  selectedOccupant: null || selectedOccupant,
  selectedUser: null || selectedUser,
  errorMessage: null,
};

export const AppReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_STATUS':
      return {
        ...initialState,
      };
    case 'SET_DISASTER':
      return {
        ...initialState,
        selectedDisaster: action.payload,
        selectedBasecamp: null,
        selectedProperty: null,
        selectedRoom: null,
        selectedOccupant: null,
        errorMessage: null,
      };
    case 'SET_BASECAMP':
      return {
        ...initialState,
        selectedBasecamp: action.payload,
        selectedProperty: null,
        selectedRoom: null,
        selectedOccupant: null,
        errorMessage: null,
      };
    case 'SET_PROPERTY':
      return {
        ...initialState,
        selectedProperty: action.payload,
        selectedRoom: null,
        selectedOccupant: null,
        errorMessage: null,
      };
    case 'SET_ROOM':
      return {
        ...initialState,
        selectedRoom: action.payload,
        selectedOccupant: null,
        errorMessage: null,
      };
    case 'SET_OCCUPANT':
      return {
        ...initialState,
        selectedOccupant: action.payload,
        errorMessage: null,
      };
    case 'SET_USER':
      return {
        ...initialState,
        selectedUser: action.payload,
        errorMessage: null,
      };
    case 'SET_ERROR':
      return {
        ...initialState,
        errorMessage: action.error.message,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
