import React, { Fragment } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthState } from '../contexts/auth';

const AppRoute = ({
  component: Component,
  path,
  isPrivate,
  isAdmin,
  layout,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const userDetails = useAuthState();
  const Layout = layout || Fragment;

  return (
    <Route
      path={path}
      render={(props) =>
        isPrivate && !Boolean(userDetails.token) ? (
          <Redirect to={{ pathname: `/login?redirect=${pathname}${search}` }} />
        ) : isAdmin && !userDetails.userLevel.includes('admin') ? (
          <Redirect to={{ pathname: '/disasters' }} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
      {...rest}
    />
  );
};

export default AppRoute;
