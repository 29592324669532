import { Auth } from 'aws-amplify';

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await Auth.signIn(loginPayload.email, loginPayload.password);
    if (response.challengeName) {
      dispatch({ type: 'LOGIN_CHALLENGED', payload: response });
    } else {
      dispatch({ type: 'LOGIN_SUCCESS', payload: response });

      if (
        response.signInUserSession.accessToken.payload['cognito:groups'] ===
          undefined ||
        response.signInUserSession.accessToken.payload['cognito:groups']
          .length === 0
      )
        dispatch({ type: 'SET_USER_LEVEL', payload: 'agent' });
      else
        dispatch({
          type: 'SET_USER_LEVEL',
          payload:
            response.signInUserSession.accessToken.payload['cognito:groups'],
        });

      let currentUser = {
        username: response.username,
        email: response.attributes.email,
        accessToken: response.signInUserSession.accessToken.jwtToken,
        userLevel:
          response.signInUserSession.accessToken.payload['cognito:groups'] ||
          'agent',
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }

    return response;
  } catch (error) {
    console.log(error);
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function completeNewPassword(dispatch, challengePayload) {
  try {
    const response = await Auth.signIn(
      challengePayload.email,
      challengePayload.oldPassword
    );

    if (response.challengeName) {
      await Auth.completeNewPassword(response, challengePayload.newPassword, {})
        .then(() => {
          //dispatch({ type: 'LOGIN_SUCCESS', payload: user });
        })
        .catch((error) => {
          dispatch({ type: 'LOGIN_ERROR', error: error });
          return;
        });

      const user = await Auth.currentAuthenticatedUser();
      dispatch({ type: 'LOGIN_SUCCESS', payload: user });

      if (
        user.signInUserSession.accessToken.payload['cognito:groups'] ===
          undefined ||
        user.signInUserSession.accessToken.payload['cognito:groups'].length ===
          0
      )
        dispatch({ type: 'SET_USER_LEVEL', payload: 'agent' });
      else
        dispatch({
          type: 'SET_USER_LEVEL',
          payload: user.signInUserSession.accessToken.payload['cognito:groups'],
        });

      let currentUser = {
        username: user.username,
        email: user.attributes.email,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        userLevel:
          user.signInUserSession.accessToken.payload['cognito:groups'] ||
          'agent',
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return currentUser;
    } else {
      dispatch({ type: 'LOGIN_SUCCESS', payload: response });
      if (
        response.signInUserSession.accessToken.payload['cognito:groups'] ===
          undefined ||
        response.signInUserSession.accessToken.payload['cognito:groups']
          .length === 0
      )
        dispatch({ type: 'SET_USER_LEVEL', payload: 'agent' });
      else
        dispatch({
          type: 'SET_USER_LEVEL',
          payload:
            response.signInUserSession.accessToken.payload['cognito:groups'],
        });

      let currentUser = {
        username: response.username,
        email: response.attributes.email,
        accessToken: response.signInUserSession.accessToken.jwtToken,
        userLevel:
          response.signInUserSession.accessToken.payload['cognito:groups'] ||
          'agent',
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return currentUser;
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}
