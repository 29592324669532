import { lazy } from 'react';
import NotFound from './layout/NotFound';
import DashboardLayout from './layout/DashboardLayout';
const Routes = [
  {
    path: '/login*',
    component: lazy(() => import('./containers/Login')),
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: '/new/disaster',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Disaster/NewDisaster')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/edit/disaster',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Disaster/EditDisaster')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/disasters',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Disaster/Disasters')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/disasteragents',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Disaster/DisasterAgents')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/disasterlog',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Disaster/DisasterLog')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/new/basecamp',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Basecamp/NewBasecamp')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/edit/basecamp',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Basecamp/EditBasecamp')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/basecamps',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Basecamp/Basecamps')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/properties',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Property/Properties')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/new/property',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Property/NewProperty')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/edit/property',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Property/EditProperty')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/rooms',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Room/Rooms')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/new/room',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Room/NewRoom')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/edit/room',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Room/EditRoom')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/roomoccupants',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Room/RoomOccupants')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/new/occupant',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Occupant/NewOccupant')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/edit/occupant',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Occupant/EditOccupant')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/occupants',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Occupant/Occupants')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/new/user',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/User/NewUser')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/edit/user',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/User/EditUser')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/users',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/User/Users')),
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: '/account',
    layout: DashboardLayout,
    component: lazy(() => import('./containers/Account')),
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: '/',
    component: lazy(() => import('./containers/Home')),
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: '/*',
    component: NotFound,
    isPrivate: false,
    isAdmin: false,
  },
];

export default Routes;
